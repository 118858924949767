<template>
  <b-sidebar
    id="add-new-ride-type-sidebar"
    :visible="isAddNewRideTypeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="onFormToggle"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{isEdit ? 'Update Ride Type' : 'Add New Ride Type'}}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Ride Type Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="rideTypeData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Car"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          
          <!-- Ride Type Commission -->
          <validation-provider
            #default="validationContext"
            name="Commission"
            rules="required"
          >
            <b-form-group
              label="Commission (in %)"
              label-for="commission"
            >
              <b-form-input
                id="commission"
                v-model="rideTypeData.baseFare"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="0"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


           <!-- Base Type -->
          <validation-provider name="BaseType" #default="validationContext">
            <b-form-group
              label="BaseType"
              label-for="baseType"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="rideTypeData.baseType"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="baseTypeOptions"
                :reduce="(val) => val.value"
                :clearable="true"
                input-id="baseType"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
               {{isEdit ? 'Update' : 'Add'}}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import useRideType from '@/views/apps/ride-type/useRideType';
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewRideTypeSidebarActive',
    event: 'update:is-add-new-ride-type-sidebar-active',
  },
  props: {
    isAddNewRideTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    rideTypeToUpdate: {
      type: Object,
      required: false,
    },
    baseTypeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
    }
  },
  setup(props, { emit }) {
    let blankRideTypeData = {
      name: null,
      baseFare: null,
      baseType: 0,
    }

    const rideTypeData = ref(((blankRideTypeData)))
    const resetrideTypeData = () => {
      rideTypeData.value = ((blankRideTypeData))
    }

    const onSubmit = () => {
      console.log(props.isEdit)
       if (props.isEdit) {
         dispatchUpdateRideType();
      } else {
        dispatchAddRideType();
      }
    }


    const dispatchAddRideType = () => {
       store.dispatch('rider/addRideType', rideTypeData.value)
        .then((rideType) => {
          setRideType(rideType)
          emit('refetch-data')
          emit('update:is-add-new-ride-type-sidebar-active', false)
        }).catch((message) => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error: '+message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    };

    const dispatchUpdateRideType = () => {
      updateRideType(rideTypeData.value)
      .then((_) => {
        emit('refetch-data')
        emit('update:is-add-new-ride-type-sidebar-active', false)
      })
      .catch(({response}) => {
        console.log(response);
        toast({
          component: ToastificationContent,
          props: {
            title: `Error: ${message}`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    };

    const onFormToggle = (value) => {
      emit('update:is-add-new-ride-type-sidebar-active', value)
      if(value) {
          if(props.isEdit) {
              blankRideTypeData = {...props.rideTypeToUpdate};
              blankRideTypeData.baseType = props.rideTypeToUpdate?.baseType?.id
              rideTypeData.value = blankRideTypeData
          } else {
              props.isEdit = false;
              blankRideTypeData = {
                  id: null,
                  name: null,
                  baseFare: 0,
                  baseType: null,
              }; 
              rideTypeData.value = blankRideTypeData
          }
      }
    }

    const toast = useToast()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetrideTypeData)

    const {
        setRideType,
        updateRideType
    } = useRideType()

    return {
      rideTypeData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      onFormToggle
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-driver-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
