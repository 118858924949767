<template>

  <div>
       <add-new
        :is-add-new-ride-type-sidebar-active.sync="isAddNewRideTypeSidebarActive"
        :is-edit.sync="isEdit"
        :ride-type-to-update="rideTypeToUpdate"
        :base-type-options="baseRideTypes.map((r) => ({ label: r.name, value: r.id }))"
        @refetch-data="refetchData"
      />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Referesh -->
          <b-col cols="12"
          md="3">
             <b-button
                variant="primary"
                @click="refreshData()"
              >
               <feather-icon icon="RefreshCcwIcon" />
              </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
                <b-button
                variant="primary"
                @click="openForm()"
                v-if="$can(Action.Create, Subject.RIDE_TYPE)"
              >
                <span class="text-nowrap">Add Ride Type</span>
              </b-button>               
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refRideTypeTable"
        class="position-relative"
        :items="rideTypes"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="button"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
              <b-dropdown-item @click="editRideType(data.item)" v-if="$can(Action.Update, Subject.RIDE_TYPE)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteRideType(data.item)" v-if="$can(Action.Delete, Subject.RIDE_TYPE)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRideTypes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, 
      BDropdown, BDropdownItem, BDropdownItemButton, BPagination} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import useRideType from './useRideType'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import AddNew from './components/AddNew.vue'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useBaseRideType from '../base-ride-type/useBaseRideType'
import { Action, Subject } from '@/libs/acl/config'

export default {
  components: {
    AddNew,
    BDropdown, 
    BDropdownItem, 
    BDropdownItemButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    vSelect
  },
  directives: {
    Ripple,
  },
   data() {
    return {
      rideTypeToUpdate: null,
    }
  },
  methods: {
    deleteRideType(rideType) {
         this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${rideType.name}.`, {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Delete',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value) {
            store.dispatch('rider/deleteRideType', rideType)
        .then(_ => {
          this.removeRideType(rideType.id)
          this.refetchData()
        }).catch((message) => {
         this.toast({
          component: ToastificationContent,
          props: {
            title: 'Error: '+message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        });
          }
        })
    },
    editRideType(rideType) {
      this.rideTypeToUpdate = rideType;
      this.openForm(true);
    },
  },
  setup() {

    onMounted(() => {
      fetchRideTypes()
    })
    const isAddNewRideTypeSidebarActive = ref(false)
    const isEdit = ref(false);
    const toast = useToast()
    const { baseRideTypes, fetchBaseRideTypes } = useBaseRideType();


    const openForm = (update = false) => {
      console.log(update)
      isEdit.value = update;
      fetchBaseRideTypes();
      isAddNewRideTypeSidebarActive.value = true;
    };


    // const baseTypeOptions = [
    //   { label: "Car", value: "Car" },
    //   { label: "Bike", value: "Bike" },
    //   { label: "Truck", value: "Truck" },
    // ];

    const {
      fetchRideTypes,
      rideTypes,      
      tableColumns,
      perPage,
      currentPage,
      totalRideTypes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRideTypeTable,
      refetchData,
      refreshData,
      removeRideType,
    } = useRideType()

    return {
      isAddNewRideTypeSidebarActive,
      isEdit,

      openForm,
      fetchRideTypes,
      rideTypes,
      baseRideTypes,
      
      tableColumns,
      perPage,
      currentPage,
      totalRideTypes,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRideTypeTable,
      refetchData,
      refreshData,
      removeRideType,
      toast,
      Action,
      Subject
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
